import {
    BookingPaymentsGridResponseModel,
    BookingPaymentsFilterApiModel,
} from './../../../models/payment/payment.model';
import { SettingsPaymentsService } from './settings-payments.service';
import { Component, OnInit, ViewChild, AfterContentInit } from '@angular/core';
import { AuthTokenService } from '../../../shared/services/auth-token.service';
import { EnumModel } from '../../../models/shared/enum-model';
import { GridConfiguration, DxGridHelper } from '../../../shared/helpers/dx-grid-helper';
// import { BookingPaymentsGridModel } from '../../../models/payment/payment.model';
import { Router } from '@angular/router';
import { FinancePaymentsPermissionsModel } from '../../../models/permissions/permissions.model';
import { PermissionsService } from '../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../enums/permissions.enum';
import { CookieService } from 'ngx-cookie-service';
import { DxSelectBoxComponent, DxDataGridComponent } from 'devextreme-angular';

@Component({
    selector: 'app-settings-payments',
    templateUrl: './settings-payments.component.html',
    styleUrls: ['./settings-payments.component.less'],
})
export class SettingsPaymentsComponent implements OnInit, AfterContentInit {
    @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;
    @ViewChild('status') statusValue: DxSelectBoxComponent;
    @ViewChild('type') typeValue: DxSelectBoxComponent;
    cookieDateFrom: string;
    cookieDateTo: string;
    cookieStatus: string;
    cookieType: string;
    permissionsModel: FinancePaymentsPermissionsModel;

    theme: number;
    total: number;
    currencySymbol: string;
    searchValue: string;
    dateTo: Date = new Date();
    dateFrom: Date = new Date();
    showFilter: boolean;
    loader: boolean;

    bookingPaymentsRequest = new BookingPaymentsFilterApiModel();
    gridConfiguration: GridConfiguration;

    paymentStatusSource: EnumModel[];
    paymentTypeSource: EnumModel[];

    constructor(
        private settingsPaymentsService: SettingsPaymentsService,
        private cookieService: CookieService,
        private router: Router,
        private permissionsService: PermissionsService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = this.permissionsService.createPermissionsModel(PermissionsEnum.FinancePayments);
        this.currencySymbol = AuthTokenService.decodeJwtToken().Currency.Symbol;
    }

    ngOnInit() {
        this.cookieDateFrom = this.cookieService.get('DateFromPayment');
        this.cookieDateTo = this.cookieService.get('DateToPayment');
        this.cookieStatus = this.cookieService.get('StatusPayment');
        this.cookieType = this.cookieService.get('TypePayment');

        this.gridConfiguration = new BookingPaymentsGridResponseModel();
        this.gridConfiguration.service = this.settingsPaymentsService;
        if (this.cookieDateFrom && this.cookieDateTo) {
            this.showFilter = true;
            this.dateFrom = new Date(this.cookieDateFrom);
            this.dateTo = new Date(this.cookieDateTo);
            this.bookingPaymentsRequest.from = new Date(this.dateFrom).toDateString();
            this.bookingPaymentsRequest.to = new Date(this.dateTo).toDateString();
            if (this.cookieStatus !== null) {
                this.bookingPaymentsRequest.paymentStatus = this.cookieStatus;
            }
            if (this.cookieType) {
                this.bookingPaymentsRequest.paymentType = JSON.parse(this.cookieType);
            }
        }

        this.settingsPaymentsService.getDataForBookingPaymentSearch().subscribe(
            (res) => {
                this.paymentStatusSource = res.paymentStatus;
                this.paymentTypeSource = res.paymentType;
            },
            (error) => console.log(error)
        );
    }

    ngAfterContentInit(): void {
        this.initGrid();
    }

    initGrid(): void {
        DxGridHelper.ConfigureGrid(this.grid, this.bookingPaymentsRequest, this.gridConfiguration, this);
    }

    reloadGrid() {
        this.bookingPaymentsRequest.from = this.dateFrom ? this.dateFrom.toDateString() : null;
        this.bookingPaymentsRequest.to = this.dateTo ? this.dateTo.toDateString() : null;
        this.bookingPaymentsRequest.paymentStatus = this.statusValue.value ? this.statusValue.value : null;
        this.bookingPaymentsRequest.paymentType = this.typeValue.value ? this.typeValue.value : [];
        this.bookingPaymentsRequest.options.customSearch[0].value = this.searchValue;

        this.cookieService.set('DateFromPayment', new Date(this.bookingPaymentsRequest.from).toDateString(), 0.01);
        this.cookieService.set('DateToPayment', new Date(this.bookingPaymentsRequest.to).toDateString(), 0.01);
        this.cookieService.set('StatusPayment', this.bookingPaymentsRequest.paymentStatus, 0.01);
        this.cookieService.set('TypePayment', JSON.stringify(this.bookingPaymentsRequest.paymentType), 0.01);

        this.gridConfiguration.reloadGrid();
    }

    toogleFilter() {
        this.showFilter = !this.showFilter;
    }

    clearFilters() {
        this.searchValue = '';
        this.dateTo = new Date();
        this.dateFrom = new Date();
        this.statusValue.value = null;
        this.typeValue.value = null;
        this.bookingPaymentsRequest.from = new Date().toDateString();
        this.bookingPaymentsRequest.to = new Date().toDateString();
        this.bookingPaymentsRequest.paymentStatus = null;
        this.bookingPaymentsRequest.paymentType = [];
        this.bookingPaymentsRequest.options.customSearch[0].value = '';
        this.cookieService.delete('DateFromPayment');
        this.cookieService.delete('DateToPayment');
        this.cookieService.delete('StatusPayment');
        this.cookieService.delete('TypePayment');
    }

    searchPayments() {
        this.gridConfiguration.customSearch[0].value = this.searchValue;
        this.gridConfiguration.reloadGrid();
    }

    goToBooking(data) {
        if (data.bookingId) {
            if (data.isFunction) {
                this.router.navigate(['function/details/', data.bookingId]);
            }
            else {
                this.router.navigate(['booking/details/', data.bookingId]);
            }       
        }
        return;
    }

    exportPayments(e, a) {
        e.preventDefault();
        this.loader = true;
        const fileName = `Payments export - ${new Date().toDateString()}.csv`;

        this.settingsPaymentsService.getBookingPaymentsCsv(this.bookingPaymentsRequest).subscribe((res) => {
            if (res) {
                const url = window.URL.createObjectURL(res);
                a.href = url;
                a.target = '_blank';

                // target filename
                a.download = fileName;

                a.click();

                a.removeAttribute('download');
                a.removeAttribute('href');
                a.removeAttribute('target');
                this.loader = false;
            }
        });
    }

    onDataLoaded(response) {
        this.total = response.totalAmountForRange;
    }

    paymentClass(payment: any) {
        switch (payment.status) {
            case "Failed":
                return "payment-failed";
            case "Pending":
                return "payment-pending";
            case "Cancelled":
                return "payment-cancelled";
            default: 
                return "";
        }
    }
}
