import { Injectable } from '@angular/core';
import { HttpRequestService } from 'src/app/shared/services/http-request.service';
import { environment } from 'src/environments/environment';
import { FunctionSearchModel, FunctionFlowModel, FunctionExtraModel, FunctionPaymentModel, FunctionRoomModel, FunctionEmailModel } from 'src/app/models/function-room/function-flow.model'
import { PciFunctionDetailModel } from 'src/app/models/booking/pci-booking-detail.model';
import { FunctionPrintModel } from 'src/app/models/function-room/function-flow.model';
import { InvoiceDetailsModel, InvoiceItemModel } from 'src/app/models/booking/invoice-details-pd.model';
import { BookingFunction } from 'src/app/models/function-room/function.model';
import { PciNewFunctionModel } from 'src/app/models/booking/pci-new-booking.model';
import { FinishCmsFunctionPaymentModel } from 'src/app/models/function-room/finish-cms-function-payment.model';
import { AuthTokenService } from '../../../shared/services/auth-token.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PaymentActionType } from 'src/app/enums/payment-action-type.enum';
import { FunctionEmailType } from 'src/app/enums/function.enum';

@Injectable()
export class NewFunctionService {
    private newFunctionUrl = environment.apiUrl + '/Function/NewFunction';
    private getFunctionsUrl = environment.apiUrl + '/Function/GetFunctions';
    private getFunctionUrl = environment.apiUrl + '/Function/GetFunction';
    private checkAvailabilityUrl = environment.apiUrl + '/Function/CheckAvailability';
    private getAvailabilityUrl = environment.apiUrl + '/Function/GetFunctionRoomAvailability';
    private getRatePlanSaleItemsForFunctionUrl = environment.apiUrl + '/Function/GetRatePlanSaleItemsForFunction';

    private getCaterpayHtmlFormForNewFunctionUrl = environment.apiUrl + '/Payment/GetCaterpayHtmlFormForNewFunction';
    private getCaterpayHtmlFormForNewPaymentUrl = environment.apiUrl + '/Payment/GetCaterpayHtmlFormForNewPaymentForFunction';
    private getPciCardEntryFormUrl = environment.apiUrl + '/Payment/GetCardEntryForm';

    private insertFunctionUrl = environment.apiUrl + '/Function/InsertFunction';
    private updateFunctionUrl = environment.apiUrl + '/Function/UpdateFunction';
    private newPaymentUrl = environment.apiUrl + '/Function/NewPayment';
    private editPaymentUrl = environment.apiUrl + '/Function/EditPayment';

    private getInvoiceBalanceUrl = environment.apiUrl + '/Function/GetInvoiceBalance';
    private getCardListUrl = environment.apiUrl + '/Payment/GetCardListForFunction';

    private insertPaymentUrl = environment.apiUrl + '/Function/InsertPayment';
    private updatePaymentUrl = environment.apiUrl + '/Function/UpdatePayment';
    private pciNewPaymentUrl = environment.apiUrl + '/Payment/PciNewFunctionPayment';
    private pciNewFunctionUrl = environment.apiUrl + '/Payment/PciNewFunction';
    private processPaymentDirectUrl = environment.apiUrl + '/Payment/ProcessFunctionPaymentDirect';

    private newInvoiceUrl = environment.apiUrl + '/Function/NewInvoice';
    private createInvoiceUrl = environment.apiUrl + '/Function/CreateInvoice';
    private closeInvoiceUrl = environment.apiUrl + '/Function/CloseInvoice';
    private deleteInvoiceUrl = environment.apiUrl + '/Function/DeleteInvoice';
    private editInvoiceUrl = environment.apiUrl + '/Function/EditInvoice';
    private updateInvoiceUrl = environment.apiUrl + '/Function/UpdateInvoice';
    private createCreditNoteUrl = environment.apiUrl + '/Function/CreateCreditNote';

    private deleteInvoiceItemUrl = environment.apiUrl + '/Function/DeleteInvoiceItem';
    private deleteInvoicePaymentUrl = environment.apiUrl + '/Function/DeleteInvoicePayment';
    private insertInvoiceItemsUrl = environment.apiUrl + '/Function/InsertInvoiceItems';
    private insertInvoicePaymentsUrl = environment.apiUrl + '/Function/InsertInvoicePayments';

    private getRoomItemsForInvoiceUrl = environment.apiUrl + '/Function/GetRoomItemsForInvoice';
    private getExtraItemsForInvoiceUrl = environment.apiUrl + '/Function/GetExtraItemsForInvoice';
    private getPaymentItemsForInvoiceUrl = environment.apiUrl + '/Function/GetPaymentItemsForInvoice';

    private getPropertyContactUrl = environment.apiUrl + '/Property/GetPropertyContact';
    private linkToBookingUrl = environment.apiUrl + '/Function/LinkToBooking';

    private functionRoomCheckInUrl = environment.apiUrl + '/Function/FunctionRoomCheckIn';
    private functionRoomCheckOutUrl = environment.apiUrl + '/Function/FunctionRoomCheckOut';

    private deleteBookingLinkUrl = environment.apiUrl + '/Function/DeleteBookingLink';
    private addBookingLinkUrl = environment.apiUrl + '/Function/AddBookingLink';
    private deleteFunctionRoomUrl = environment.apiUrl + '/Function/DeleteFunctionRoom';
    private saveFunctionRoomUrl = environment.apiUrl + '/Function/SaveFunctionRoom';
    private saveFunctionRoomItineraryUrl = environment.apiUrl + '/Function/SaveFunctionRoomItinerary';

    private sendFunctionEmailUrl = environment.apiUrl + '/Function/SendFunctionEmail';
    private saveFunctionExtraUrl = environment.apiUrl + '/Function/SaveFunctionExtra';
    private deleteFunctionExtraUrl = environment.apiUrl + '/Function/DeleteFunctionExtra';

    private setFunctionStatusUrl = environment.apiUrl + '/Function/SetFunctionStatus';
    private summaryPdfUrl = environment.apiUrl + '/Function/SummaryPDF';

    private processEmailTemplateUrl = environment.apiUrl + '/Function/ProcessEmailTemplate';
    private voidPaymentUrl = environment.apiUrl + '/Function/VoidPayment';

    private receiptData: FunctionPrintModel;

    constructor(private http: HttpRequestService, private httpOriginal: HttpClient) {}

    newFunction(includeInactive:boolean) {
        return this.http.get(this.newFunctionUrl + '?includeInactive=' + includeInactive);
    }

    getFunctions() {
        return this.http.get(this.getFunctionsUrl);
    }

    getFunction(Id: number) {
        return this.http.get(this.getFunctionUrl + '?Id=' + Id);
    }

    checkAvailability(propertyFunctionRoomId: number, functionId: number, date: Date, morningSession: boolean, afternoonSession: boolean, eveningSession:boolean) {
        return this.http.get(this.checkAvailabilityUrl + '?propertyFunctionRoomId=' + propertyFunctionRoomId + '&functionId=' + functionId + '&date=' + new Date(date).toJSON() + '&morningSession=' + morningSession + '&afternoonSession=' + afternoonSession + '&eveningSession=' + eveningSession);
    }

    getAvailability(propertyFunctionRoomId: number, date: Date) {
        return this.http.get(this.getAvailabilityUrl + '?propertyFunctionRoomId=' + propertyFunctionRoomId + '&date=' + new Date(date).toJSON());
    }

    getRatePlanSaleItemsForFunction(ratePlanId: number) {
        return this.http.get(this.getRatePlanSaleItemsForFunctionUrl + '?ratePlanId=' + ratePlanId);
    }

    getCaterpayHtmlFormForNewFunction(model: FunctionFlowModel) {
        return this.http.post(this.getCaterpayHtmlFormForNewFunctionUrl, model, false);
    }

    getCaterpayHtmlFormForNewPayment(model: FunctionPaymentModel) {
        return this.http.post(this.getCaterpayHtmlFormForNewPaymentUrl, model);
    }

    getPciCardEntryForm() {
        return this.http.get(this.getPciCardEntryFormUrl);
    }

    insertFunction(model: FunctionFlowModel) {
        return this.http.post(this.insertFunctionUrl, model, false);
    }

    updateFunction(model: FunctionFlowModel) {
        return this.http.post(this.updateFunctionUrl, model, false);
    }

    newPayment(functionId: number,  paymentActionType: PaymentActionType, sourcePayment: FunctionPaymentModel) {
        let sourcePaymentId = sourcePayment == null ? 0 : sourcePayment.id;
        return this.http.get(this.newPaymentUrl + '?functionId=' + functionId+ '&paymentActionType=' + paymentActionType + '&sourcePaymentId=' + sourcePaymentId);
    }

    insertPayment(model: FunctionPaymentModel) {
        return this.http.post(this.insertPaymentUrl, model, true);
    }

    updatePayment(model: FunctionPaymentModel) {
        return this.http.post(this.updatePaymentUrl, model, true);
    }

    editPayment(functionId: number, functionPaymentId: number) {
        return this.http.get(this.editPaymentUrl + '?functionId=' + functionId + '&functionPaymentId=' + functionPaymentId);
    }

    processPaymentDirect(model: FinishCmsFunctionPaymentModel) {
        return this.http.post(this.processPaymentDirectUrl, model, false);
    }

    getInvoiceBalance(invoiceId: number) {
        return this.http.get(this.getInvoiceBalanceUrl + '?invoiceId=' + invoiceId);
    }

    getCardList(model: FunctionPaymentModel) {
        return this.http.post(this.getCardListUrl, model);
    }

    pciNewPayment(model: PciFunctionDetailModel) {
        return this.http.post(this.pciNewPaymentUrl, model, true);
    }

    setReceiptData(data: FunctionPrintModel) {
        this.receiptData = data;
    }

    getReceiptData() {
        return this.receiptData;
    }

    clearReceiptData(): void {
        this.receiptData = null;
    }

    getPropertyContact() {
        return this.http.get(this.getPropertyContactUrl);
    }

    newInvoice(functionId: number) {
        return this.http.get(this.newInvoiceUrl + '?functionId=' + functionId);
    }

    editInvoice(invoiceId: number) {
        return this.http.get(this.editInvoiceUrl + '?invoiceId=' + invoiceId);
    }

    createInvoice(model: InvoiceDetailsModel) {
        return this.http.put(this.createInvoiceUrl, model, true);
    }

    closeInvoice(invoiceId: number) {
        return this.http.get(this.closeInvoiceUrl + '?invoiceId=' + invoiceId, true);
    }

    deleteInvoice(invoiceId: number) {
        return this.http.post(this.deleteInvoiceUrl + '?invoiceId=' + invoiceId, true);
    }

    updateInvoice(model: InvoiceDetailsModel) {
        return this.http.put(this.updateInvoiceUrl, model, true);
    }

    deleteInvoiceItem(model: InvoiceItemModel) {
        return this.http.post(this.deleteInvoiceItemUrl, model, true);
    }

    deleteInvoicePayment(model: FunctionPaymentModel) {
        return this.http.post(this.deleteInvoicePaymentUrl, model, true);
    }

    insertInvoiceItems(model: InvoiceItemModel[]) {
        return this.http.put(this.insertInvoiceItemsUrl, model, true);
    }

    getRoomItemsForInvoice(functionId: number) {
        return this.http.get(this.getRoomItemsForInvoiceUrl + '?functionId=' + functionId);
    }

    getExtraItemsForInvoice(functionId: number) {
        return this.http.get(this.getExtraItemsForInvoiceUrl + '?functionId=' + functionId);
    }

    getPaymentItemsForInvoice(functionId: number) {
        return this.http.get(this.getPaymentItemsForInvoiceUrl + '?functionId=' + functionId);
    }

    insertInvoicePayments(model: FunctionPaymentModel[]) {
        return this.http.put(this.insertInvoicePaymentsUrl, model, true);
    }

    linkToBooking(model: BookingFunction) {
        return this.http.post(this.linkToBookingUrl, model, true);
    }
    
    createCreditNote(invoiceId: number) {
        return this.http.get(this.createCreditNoteUrl + '?invoiceId=' + invoiceId, true);
    }

    functionRoomCheckIn(functionRoomId: number, isCalendar = false) {
        return this.http.get(
            this.functionRoomCheckInUrl +
                '?functionRoomId=' +
                functionRoomId +
                '&isCalendar=' +
                isCalendar
        );
    }

    functionRoomCheckOut(functionRoomId: number) {
        return this.http.get(this.functionRoomCheckOutUrl + '?functionRoomId=' + functionRoomId);
    }

    pciNewFunction(model: PciNewFunctionModel) {
        return this.http.post(this.pciNewFunctionUrl, model);
    }

    deleteBookingLink(model: BookingFunction) {
        return this.http.post(this.deleteBookingLinkUrl, model);
    }

    addBookingLink(model: BookingFunction) {
        return this.http.post(this.addBookingLinkUrl, model);
    }

    deleteFunctionRoom(model: FunctionRoomModel) {
        return this.http.post(this.deleteFunctionRoomUrl, model);
    }

    saveFunctionRoom(model: FunctionRoomModel) {
        return this.http.post(this.saveFunctionRoomUrl, model);
    }

    saveFunctionRoomItinerary(model: FunctionRoomModel) {
        return this.http.post(this.saveFunctionRoomItineraryUrl, model);
    }

    sendFunctionEmail(model: FunctionEmailModel) {
        return this.http.post(this.sendFunctionEmailUrl, model);
    }

    saveFunctionExtra(model: FunctionExtraModel) {
        return this.http.post(this.saveFunctionExtraUrl, model);
    }

    deleteFunctionExtra(model: FunctionExtraModel) {
        return this.http.post(this.deleteFunctionExtraUrl, model);
    }

    setFunctionStatus(functionId: number, status: number) {
        return this.http.post(this.setFunctionStatusUrl + '?functionId=' + functionId + '&status=' + status, null);
    }

    processEmailTemplate(functionId: number, emailType: FunctionEmailType) {
        return this.http.get(this.processEmailTemplateUrl + '?functionId=' + functionId + '&emailType=' + emailType);
    }

    summaryPdf(functionId: number) {
        const token = AuthTokenService.getParsedToken();
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: token,
        });
        let url = this.summaryPdfUrl + '?functionId=' + functionId;

        return this.httpOriginal.post(`${url}`, null, {
            headers,
            responseType: 'blob',
        });
    }

    voidPayment(functionPaymentId: number) {
        return this.http.post(this.voidPaymentUrl + '?functionPaymentId=' + functionPaymentId, null);
    }
}
