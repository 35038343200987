import { GuestModel } from './guest-info.model';
import { SaleItemModel } from '../cost-center/sale-item.model';
import { RatePlanModel } from '../rate-plan/rate-plan.model';
import { RoomTypeModel } from '../room-type/room-types.model';

export class BookingExtraModel {
    public id: number;
    public bookingId: number;
    public saleItemId: number;
    public quantity: number;
    public dateFrom?: Date;
    public dateTo?: Date;
    public price: number;
    public total: number;
    public dateCreated: Date;
    public invoiceId?: number;
    public bookingRoomId?: number;
    public bookingRoom: BookingRoomModel;
    public saleItem: SaleItemModel;

    constructor() {
        this.saleItem = new SaleItemModel();
        this.bookingRoom = new BookingRoomModel();
    }
}

export class BookingGuestModel {
    public id: number;
    public bookingId: number;
    public guestId: number;
    public leadGuest: boolean;
    public bookingRoomId?: number;
    public notes: string;
    public guest: GuestModel;
    // public roomTypeName: string;
    // public bookingFrom: Date;
    // public bookingTo: Date;

    constructor() {
        this.guest = new GuestModel();
    }
}

export class BookingPaymentModel {
    public id: number;
    public propertyId: number;
    public userId: number;
    public superUserId: number;
    public paymentDate: Date = new Date();
    public paymentNo: string;
    public amount: number;
    public paymentType: string;
    public paymentPurpose: string;
    public bookingId?: number;
    public invoiceId?: number;
    public guestId?: number;
    public partnerId?: number;
    public paymentRef?: string;
    public htmlPaymentForm: string;
    public dateCreated: Date;
    public note: string;
    public merchantData: string;
    public bookingPaymentId?: number;
    public paidBy: string;

    public cards: CardModel[];

    constructor() {
        this.cards = [];
    }
}

export class BookingRoomModel {
    public id: number;
    public bookingId: number;
    public roomId: number;
    public pricePlanId: number;
    public ratePlanId: number;
    public roomTypeId: number;
    public adults: number;
    public children: number;
    public status: string;
    public bookingFrom: Date;
    public bookingTo: Date;
    public price: number;
    public invoiceId: number;
    public notes: string;
    public carReg: string;
    public eta: string;
    public etd: string;
    public dateCreated: Date;
    public ratePlan: RatePlanModel;
    public roomType: RoomTypeModel;
}

export class CardListModel {
    public customer: CustomerModel;
    public cards: CardModel[];

    constructor() {
        this.customer = new CustomerModel();
        this.cards = [];
    }
}

export class CustomerModel {
    public name: string;
    public address: string;
    public email: string;
}

export class CardModel {
    public id: number;
    public ref: number;
    public lastFourDigits: string;
    public cardExpiry: string;
    public cardSource: string;
    public hasXref: boolean;
    public guestId: number;
    public paidBy: string;
    public reportedInvalid: boolean;
    public bookingPaymentId?: number;
    public functionPaymentId?: number;
    public transactionGuid: string;
    public cardType: string;
    public vcc: boolean;
}

export class BookingGuestRegistrationMenageModel {
    public bookingGuests: Array<BookingGuestModel> = [];
    public bookingSourceId: number = null;
}

