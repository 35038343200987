<div class="pricing-plans-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']" *ngIf="!initLoader">
            <!--<h2 *ngIf="copyOfRoomTypeBaseModel && (insertRegular || insertWeekday)">Add Pricing ( {{ ratePlan.shortName }} / {{ copyOfRoomTypeBaseModel.shortName }} )</h2>-->
            <ng-container *ngIf="copyOfRoomTypeBaseModel">
                <div class="title-update-pricing">
                    <h2>{{ permissionsModel.formButtonEnabled ? 'Set Pricing' : 'Pricing' }}</h2>
                    <div class="form-item">
                        <dx-select-box
                            class="custom-select"
                            [dataSource]="ratePlans"
                            [(value)]="copyOfRatePlanModel.id"
                            (onItemClick)="filterByRatePlan($event)"
                            displayExpr="name"
                            valueExpr="id"
                            [disabled]="!permissionsModel.formButtonEnabled"
                        >
                        </dx-select-box>
                    </div>
                    <div class="form-item">
                        <dx-select-box
                            class="custom-select"
                            [(value)]="copyOfRoomTypeBaseModel.id"
                            [dataSource]="roomSource"
                            (onItemClick)="filterByRoomType($event)"
                            displayExpr="roomType.shortName"
                            valueExpr="roomType.id"
                            [disabled]="!permissionsModel.formButtonEnabled"
                        >
                        </dx-select-box>
                        <span class="text-danger" *ngIf="roomSource.length == 0">Room type is required</span>
                    </div>
                </div>
            </ng-container>

            <form [formGroup]="valForm" (ngSubmit)="submitForms($event)">
                <div class="form-box flex pricing-plan-wrapper">
                    <div class="form-item short">
                        <div class="custom-label">Date from</div>
                        <dx-date-box
                            [(value)]="dateFrom"
                            [displayFormat]="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            [acceptCustomValue]="false"
                            (onValueChanged)="filterDayOfWeek(); changeDateFrom()"
                            type="date"
                            [disabled]="!permissionsModel.formButtonEnabled"
                        >
                        </dx-date-box>
                    </div>
                    <div class="form-item short">
                        <div class="custom-label">Date to</div>
                        <dx-date-box
                        [(value)]="dateTo"
                            [displayFormat]="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            [acceptCustomValue]="false"
                            (onValueChanged)="filterDayOfWeek(); changeDateTo()"
                            type="date"
                            [disabled]="!permissionsModel.formButtonEnabled"
                        >
                        </dx-date-box>
                    </div>
                    <!--<div class="form-item"></div>-->
                    <div class="form-item short">
                        <div class="custom-label">Minimum stay</div>
                        <ng-container *ngIf="!updateRegular && !updateWeekday">
                            <input
                                class="custom-input"
                                type="number"
                                min="1"
                                formControlName="minLos"
                                name="minLos"
                                [(ngModel)]="pricePlanModel.minLoS"
                                [readonly]="!permissionsModel.formButtonEnabled"
                            />
                            <span
                                class="text-danger"
                                *ngIf="
                                    valForm.controls['minLos'].hasError('required') &&
                                    (valForm.controls['minLos'].dirty || valForm.controls['minLos'].touched)
                                "
                                >Field is required.</span
                            >
                            <span
                                class="text-danger"
                                *ngIf="
                                    valForm.controls['minLos'].hasError('min') &&
                                    (valForm.controls['minLos'].dirty || valForm.controls['minLos'].touched)
                                "
                                >Minimum number is 1.</span
                            >
                            <span class="text-danger" *ngIf="validateMinLos() && (insertRegular || insertWeekday)"
                                >Number is occupied.</span
                            >
                        </ng-container>
                        <ng-container *ngIf="updateRegular || updateWeekday">
                            <dx-select-box
                                class="custom-select"
                                [(value)]="pricePlanModel.minLoS"
                                [dataSource]="nights"
                                valueExpr="minLoS"
                                displayExpr="minLoS"
                                (onValueChanged)="clearValidationFromQualNights($event)"
                                formControlName="minLos"
                                name="minLos"
                                [disabled]="!permissionsModel.formButtonEnabled"
                            >
                            </dx-select-box>
                            <span class="text-danger">{{ errorMessageText }}</span>
                        </ng-container>
                    </div>
                    <div class="form-item short">
                        <!-- <ng-container *ngIf="insertWeekday || updateWeekday">
              <div class="custom-label">Arrival day</div>
              <dx-select-box class="custom-select"
                             [(value)]="bookingDay"
                             [dataSource]="dayOfWeekHelperFilter"
                             displayExpr="day"
                             formControlName="bookingDay"
                             name="bookingDay"
                             valueExpr="id">
              </dx-select-box>
              <span class="text-danger"
                    *ngIf="valForm.controls['bookingDay'].hasError('required') && (valForm.controls['bookingDay'].dirty || valForm.controls['bookingDay'].touched)">Field is required.</span>
            </ng-container> -->
                    </div>
                    <!--<div class="form-item short"></div>-->
                    <!--<div class="form-item short"></div>-->

                    <div class="form-item shortest">
                        <div class="custom-label">Base price</div>
                        <input
                            class="custom-input"
                            type="number"
                            min="1"
                            name="base-rate"
                            formControlName="base-rate"
                            [(ngModel)]="pricePlanModel.baseRate"
                            [readonly]="!permissionsModel.formButtonEnabled"
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['base-rate'].hasError('required') &&
                                (valForm.controls['base-rate'].dirty || valForm.controls['base-rate'].touched)
                            "
                            >Base rate is required.</span
                        >
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['base-rate'].hasError('min') &&
                                (valForm.controls['base-rate'].dirty || valForm.controls['base-rate'].touched)
                            "
                            >Minimum number is 1.</span
                        >
                    </div>

                    <div class="form-item shortest">
                        <div class="custom-label">Extra Adult Price</div>
                        <input
                            class="custom-input"
                            type="number"
                            min="0"
                            name="add-rate"
                            [attr.disabled]="roomBased ? '' : null"
                            formControlName="add-rate"
                            [(ngModel)]="pricePlanModel.additionalRate"
                            [readonly]="!permissionsModel.formButtonEnabled"
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                valForm.controls['add-rate'].hasError('min') &&
                                (valForm.controls['add-rate'].dirty || valForm.controls['add-rate'].touched)
                            "
                            >Minimum number is 0.</span
                        >
                    </div>

                    <div class="form-item shortest extra-width"></div>
                    <div class="form-item shortest"></div>

                    <ng-container>
                        <div class="label long">
                            Select days <br />
                            <div class="form-item short select-all-wrapper">
                                <input
                                    type="checkbox"
                                    [checked]="checkedAll"
                                    (change)="selectAll($event)"
                                    id="select-all"
                                    class="css-checkbox"
                                    [disabled]="!permissionsModel.formButtonEnabled"
                                />
                                <label for="select-all" class="css-label radGroup1"></label>
                                <div class="custom-label inline">Select all</div>
                            </div>
                        </div>

                        <div class="form-item short" *ngFor="let day of dayOfWeekHelper; let i = index">
                            <input
                                type="checkbox"
                                (click)="setDayOfWeek(day.id)"
                                [(ngModel)]="day.selected"
                                [ngModelOptions]="{ standalone: true }"
                                id="day-of-week{{ i }}"
                                class="css-checkbox"
                                [disabled]="!permissionsModel.formButtonEnabled"
                            />
                            <label for="day-of-week{{ i }}" class="css-label radGroup1"></label>
                            <div class="custom-label inline">{{ day.day }}</div>
                        </div>

                        <div class="form-item short"></div>

                        <div class="label long">Quick select</div>

                        <div class="form-item short">
                            <input
                                type="checkbox"
                                (change)="quickFriSat()"
                                [(ngModel)]="checkedFriSat"
                                [ngModelOptions]="{ standalone: true }"
                                id="firstSet"
                                class="css-checkbox"
                                [disabled]="!permissionsModel.formButtonEnabled"
                            />
                            <label for="firstSet" class="css-label radGroup1"></label>
                            <div class="custom-label inline">Friday - Saturday</div>
                        </div>
                        <div class="form-item short">
                            <input
                                type="checkbox"
                                (change)="quickSunThr()"
                                [(ngModel)]="checkedSunThr"
                                [ngModelOptions]="{ standalone: true }"
                                id="fsecondSet"
                                class="css-checkbox"
                                [disabled]="!permissionsModel.formButtonEnabled"
                            />
                            <label for="fsecondSet" class="css-label radGroup1"></label>
                            <div class="custom-label inline">Sunday - Thursday</div>
                        </div>

                        <div class="form-item"></div>
                    </ng-container>
                </div>

                <button
                    *ngIf="!loader && permissionsModel.formButtonEnabled"
                    type="submit"
                    class="custom-button blue left"
                >
                    Set price
                </button>
                <div *ngIf="loader" class="loader left"></div>
            </form>
            <button
                type="button"
                class="custom-button red left"
                [hidden]="loaderDelete"
                (click)="openDeleteModal()"
                *ngIf="(updateRegular || updateWeekday) && permissionsModel.delete"
            >
                Delete price
            </button>
            <div *ngIf="loaderDelete" class="loader left"></div>
            <button type="button" (click)="close()" class="custom-button red left">Close</button>
        </div>

        <div class="loader init" *ngIf="initLoader"></div>
    </div>
</div>
<app-delete-modal
    *ngIf="deletePopup"
    [(deleteModal)]="deletePopup"
    [message]="message"
    (delete)="deletePricePlan()"
></app-delete-modal>
